import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService, ApiSingleResult, ListOptions } from '@capturum/api';
import { Occupation, OccupationPayload } from '@core/interfaces/occupation.interface';
import { Observable } from 'rxjs';
import { responseData } from '@capturum/builders/core';

@Injectable({
  providedIn: 'root',
})
export class OccupationService extends ApiService<Occupation> {
  protected endpoint = 'occupation';

  constructor(public apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public setOccupation(data: OccupationPayload, options?: ListOptions): Observable<Occupation> {
    let url = `/${this.endpoint}`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.post<ApiSingleResult<Occupation>>(url, data).pipe(responseData);
  }

  public getActiveOccupation(options?: ListOptions): Observable<Occupation> {
    return this.apiHttp
      .get<ApiSingleResult<Occupation>>(`/${this.endpoint}/get-active${ApiService.generateQuery(options)}`)
      .pipe(responseData);
  }
}
