import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sumByField',
})
export class SumByFieldPipe implements PipeTransform {
  public transform(array: any[], fieldName: string): number {
    return array?.reduce((acc: number, element: any) => {
      return acc + Number(element[fieldName] || 0);
    }, 0);
  }
}
