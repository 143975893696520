<div class="popup-content">
  <div class="simple-table">
    <p-table
      styleClass="cap-info-table"
      [autoLayout]="false"
      [columns]="tableColumns"
      [hidden]="loadingOccupationData"
      [value]="tableData">
      <ng-template pTemplate="header">
        <tr>
          <th colspan="2">{{ 'hpm.set-occupation.list.type' | translate }}</th>
          <th class="select-fte-title" colspan="5">
            <div>
              <i class="fas fa-user-friends"></i>
              {{ 'hpm.set-occupation.list.current-fte' | translate }}
            </div>
          </th>
        </tr>
      </ng-template>

      <ng-template let-item pTemplate="body">
        <tr class="cap-info-table__row">
          <td class="cap-info-table__column type" colspan="2">
            {{ item.occupant_type_name }}
          </td>

          <td class="cap-info-table__column" colspan="5">
            <div class="current-fte-block">
              <cap-button
                class="negative-calculation-button"
                label="-0.33"
                [disabled]="item.fte < 0.33 || item.fte === 0"
                (onClick)="updateValue(-0.33, item.occupant_type_base_data_value_id)">
              </cap-button>
              <cap-button
                class="negative-calculation-button"
                label="-0.5"
                [disabled]="item.fte < 0.5 || item.fte === 0"
                (onClick)="updateValue(-0.5, item.occupant_type_base_data_value_id)">
              </cap-button>

              <cap-input-number
                decrementButtonClass="minus secondary"
                decrementButtonIcon="fas fa-minus"
                incrementButtonClass="plus secondary"
                incrementButtonIcon="fas fa-plus"
                [(ngModel)]="item.fte"
                [buttonLayout]="'horizontal'"
                [maxFractionDigits]="2"
                [min]="0"
                [showButtons]="true"
                [step]="1"
                (blur)="updateValue(item.fte, item.occupant_type_base_data_value_id, true)">
              </cap-input-number>

              <cap-button
                class="positive-calculation-button"
                label="+0.5"
                (onClick)="updateValue(0.5, item.occupant_type_base_data_value_id)">
              </cap-button>
              <cap-button
                class="positive-calculation-button"
                label="+0.33"
                (onClick)="updateValue(0.33, item.occupant_type_base_data_value_id)">
              </cap-button>
            </div>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="footer">
        <tr class="total-row">
          <td colspan="2">{{ 'hpm.set-occupation.total' | translate }}</td>
          <td colspan="5">
            <span class="total">{{ total | number : '1.2' }}</span>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <cap-info-table-skeleton *ngIf="loadingOccupationData"></cap-info-table-skeleton>
  </div>
</div>

<div class="popup-actions">
  <cap-button icon="fas fa-times" styleClass="primary" [label]="'button.cancel' | translate" (onClick)="cancel()">
  </cap-button>

  <cap-button icon="fas fa-check" styleClass="success" [label]="'hpm.button.save' | translate" (onClick)="save()">
  </cap-button>
</div>
