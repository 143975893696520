import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { AuthService } from '@capturum/auth';
import { InfoTableColumn } from '@capturum/ui/info-table';
import { BaseDataKeyService } from '@capturum/complete';
import { SumByFieldPipe } from '@shared/pipes/sum-by-field.pipe';
import { OccupationService } from '@shared/services/occupation.service';
import { ToastService } from '@capturum/ui/api';
import { StationStateService } from '@shared/services/station-state.service';
import { map } from 'rxjs/operators';
import { Observable, switchMap } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { Occupants, Occupation } from '@core/interfaces/occupation.interface';

@Component({
  selector: 'app-set-occupation-popup',
  templateUrl: './set-occupation-popup.component.html',
  styleUrls: ['./set-occupation-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [SumByFieldPipe, DecimalPipe],
})
export class SetOccupationPopupComponent implements OnInit {
  public tableColumns: InfoTableColumn[];
  public occupationTypes$: Observable<Occupants[]>;
  public tableData: Occupants[];
  public total = 0;
  public loadingOccupationData = true;

  constructor(
    private translateService: TranslateService,
    private dialogRef: DynamicDialogRef,
    private router: Router,
    private authService: AuthService,
    private baseDataKeyService: BaseDataKeyService,
    private sumByFieldPipe: SumByFieldPipe,
    private occupationService: OccupationService,
    private toastService: ToastService,
    private stationStateService: StationStateService,
    private decimalPipe: DecimalPipe,
  ) {
    this.baseDataKeyService.extractBaseDataKeys(this.authService.getUser());
    this.occupationTypes$ = this.getOccupationTypes();
  }

  public ngOnInit(): void {
    this.occupationTypes$.subscribe((occupation) => {
      this.tableData = occupation;
      this.loadingOccupationData = false;
      this.total = this.sumByFieldPipe.transform(this.tableData, 'fte');
    });
    this.tableColumns = [{ field: 'type' }, { field: 'currentFte' }];
  }

  public updateValue(value: number, activeType: string, manually = false): void {
    const numericNr = Number(value);

    this.tableData.forEach((item) => {
      if (item.occupant_type_base_data_value_id === activeType) {
        if (manually) {
          item.fte = value;
        } else {
          item.fte += numericNr;
        }

        if (item.fte % 1 >= 0.99) {
          item.fte = Math.ceil(item.fte);
        } else if (+(item.fte % 1).toFixed(2) == 0.01) {
          item.fte = Math.floor(item.fte);
        }
      }
    });

    this.total = this.sumByFieldPipe.transform(this.tableData, 'fte');
  }

  public cancel(occupation?: Occupation): void {
    this.dialogRef.close(occupation);
  }

  public save(): void {
    const occupants = this.tableData.filter((item) => {
      return item.fte !== 0;
    });
    const transformedTotal = this.decimalPipe.transform(this.total, '1.2');
    const payload = {
      station_id: this.stationStateService.getStation()?.id,
      occupants,
      occupants_sum: +transformedTotal,
    };

    this.occupationService.setOccupation(payload).subscribe((result) => {
      this.toastService.success(
        this.translateService.instant('toast.success.title'),
        this.translateService.instant('hpm.set-occupation.save.success'),
      );

      this.cancel(result);
    });
  }

  private getOccupationTypes(): Observable<Occupants[]> {
    return this.occupationService.getActiveOccupation({ include: ['occupants'] }).pipe(
      switchMap((occupation) => {
        return this.baseDataKeyService.getBaseDataKeyValues('occupant_type').pipe(
          map((occupantTypes) => {
            return occupantTypes.map((occupantType) => {
              const occupant = occupation?.occupants.find((item) => {
                return item.occupant_type_base_data_value_id === occupantType.value;
              });
              const activeFte = occupant ? occupant.fte : 0;

              return {
                occupant_type_base_data_value_id: occupantType.value,
                occupant_type_name: occupantType.label,
                fte: activeFte,
              };
            });
          }),
        );
      }),
    );
  }
}
