@import 'components/custom-dialog';
@import 'abstracts';

@include custom-dialog;

app-set-occupation-popup {
  .popup-content {
    padding: 33px 25.5px 29px 35px;

    @if (selector-exists(cap-info-table-skeleton)) {
      display: block;
    }
  }

  .current-fte-block {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 15px;
  }

  cap-button {
    &.negative-calculation-button p-button {
      button.cap-button.p-button {
        margin-right: 15px;
      }
    }

    &.positive-calculation-button p-button {
      button.cap-button.p-button {
        margin-left: 15px;
      }
    }

    &.negative-calculation-button p-button,
    &.positive-calculation-button p-button {
      button.cap-button.p-button {
        background: rgb(240, 240, 240);
        border: none;
        border-radius: 3px;
        height: 60px;
        width: 98px;
        color: $confirmation-header;
        font-size: 1.25rem;
        font-weight: bold;
      }
    }
  }

  .p-datatable {
    table.p-datatable-table {
      .cap-info-table__column-header {
        &.text-center {
          display: block;
          text-align: center;
        }
      }

      .p-datatable-thead {
        tr {
          th {
            font-size: 1.13rem;
            font-weight: 600;

            &:first-child {
              padding-left: 27px;
            }

            &.select-fte-title {
              text-align: center;

              div {
                margin-left: -41px;

                i {
                  margin-right: 6px;
                }
              }
            }
          }
        }
      }

      .p-datatable-tbody {
        tr {
          border: 1px solid $border-color;
          border-radius: 3px;
          height: 80px;
        }

        td {
          padding: 0;
          background: $gray-light;

          &.type {
            padding-left: 27px;
            font-size: 1.25rem;
            color: $dark-lava;
          }
        }

        .cap-button.secondary {
          background: $confirmation-header;
          border-radius: 2px;
          height: 60px;
          width: 60px;
          color: $white;
          font-size: 1.25rem;
          font-weight: bold;
        }
      }
    }

    .cap-info-table__column {
      div {
        display: flex;
        align-items: center;

        &.p-inputgroup input.p-inputtext,
        &.p-inputgroup input.p-inputtext:hover,
        &.p-inputgroup input.p-inputtext:focus,
        &.p-inputgroup input.p-inputtext:enabled:focus:not(.p-state-error),
        &.p-inputgroup input.p-inputtext:enabled:hover:not(.p-state-error) {
          color: $dark-lava;
          font-size: 1.25rem;
          text-align: center;
          width: 105px;
          background: $white;
          border-radius: 3px;
          border: 1px solid $gray;
          height: 60px;
        }
      }
    }

    .p-datatable-tfoot {
      tr {
        &.total-row {
          td {
            border: none;
            border-top: 1px solid $border-color;
            font-weight: 600;
            padding-top: 17px;
            padding-left: 29px;
            color: $dark-lava;
            font-size: 1.25rem;

            &:first-child {
              text-align: left;
            }

            .total {
              margin-left: -28px;
            }
          }
        }
      }
    }
  }

  .popup-actions {
    display: flex;
    justify-content: space-between;

    cap-button {
      button {
        height: 60px;

        span.p-button-icon,
        span.p-button-label {
          font-size: 1.25rem;
          font-weight: bold;
        }
      }
    }

    button {
      &.cap-button.success {
        background-color: $success !important; /* stylelint-disable-line declaration-no-important */
        border: 2px solid $success !important; /* stylelint-disable-line declaration-no-important */
      }
    }
  }
}

body .p-dialog {
  @if (selector-exists(app-set-occupation-popup)) {
    @include media-breakpoint-down(xl) {
      width: 95%;
    }
  }
}
