import { Injectable } from '@angular/core';
import { LocalStorageService } from '@capturum/ui/api';
import { ActiveStation, Station } from '@core/interfaces/station.interface';
import { LocalStorageKey } from '@core/enums/local-storage-key.enum';
import { ApiHttpService, ApiService } from '@capturum/api';

@Injectable({
  providedIn: 'root',
})
export class StationStateService extends ApiService<Station> {
  protected endpoint = 'station';

  constructor(private localStorageService: LocalStorageService, public apiHttpService: ApiHttpService) {
    super(apiHttpService);
  }

  public getStation(): ActiveStation {
    return this.localStorageService.getItem(LocalStorageKey.activeStation);
  }

  public setStation(activeStation: ActiveStation): void {
    this.localStorageService.setItem(LocalStorageKey.activeStation, activeStation);
  }

  public removeStation(): void {
    this.localStorageService.removeItem(LocalStorageKey.activeStation);
  }
}
